import React from 'react';
import Layout, { Content } from '../../components/Layout';
import ArrivalForm from '../../components/forms/ArrivalDate';

const ArrivalDate = () => {
  return (
    <Layout title={'Arrival Date'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <ArrivalForm />
      </Content>
    </Layout>
  );
};

export default ArrivalDate;